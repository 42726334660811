import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { LocaleProvider } from 'hooks';
import { Store } from './Store';
import { ErrorRenderer } from './ErrorRenderer';
import { ServerErrorRenderer } from './ServerErrorRenderer';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      eventId: undefined,
      hasError: false,
      captureFeedback: false,
      status: undefined
    };

    this.providedValue = {
      setStatus: this.setStatus
    };
  }

  static getDerivedStateFromError = error => ({
    error,
    ...error.status && ({ status: error.status }),
    hasError: true
  });

  componentDidCatch = (error, errorInfo) => {
    const sentryWillCatchErrorAnyway = process.env.NODE_ENV == 'development';
    if (sentryWillCatchErrorAnyway) return;
    Sentry.withScope(scope => {
      Sentry.addBreadcrumb({
        category: 'boundary',
        message: 'Error boundary was displayed',
        level: Sentry.Severity.Info
      });
      scope.setTag('error-boundary', true);
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  reportFeedback = () => {
    const { eventId } = this.state;
    Sentry.showReportDialog({ eventId });
  }

  setStatus = status => {
    const {
      statusIsError
    } = this.props;
    if (statusIsError(status)) this.setState({ status, hasError: true });
  }

  render() {
    const {
      children,
    } = this.props;

    const {
      hasError,
      status
    } = this.state;

    const serverError = this.state.error?.response?.data?.error;

    let body = children;

    if (hasError && serverError) body = (
      <LocaleProvider>
        <ServerErrorRenderer
          serverError={serverError}
        />
      </LocaleProvider>
    );
    else if (hasError) body = (
      <ErrorRenderer
        status={status}
      />
    );
    return (
      <Store.Provider value={this.providedValue} >{body}</Store.Provider>
    );
  }
}

ErrorBoundary.defaultProps = {
  statusIsError: status => [400, 403, 404, 500, 504].includes(status),
};
