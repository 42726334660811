import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { ErrorBoundary } from 'elements/boundary';
import ScrollToTop from 'lib/ScrollToTop';
import { AppSkeleton } from 'AppSkeleton';
import { ROUTES } from './Routes';

import './App.scss';

const IS_DEV = ['development', 'test'].includes(process.env.NODE_ENV);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: IS_DEV ? 1 : 3,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Suspense fallback={<AppSkeleton />}>
          <BrowserRouter>
            <ScrollToTop />
            {ROUTES()}
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
