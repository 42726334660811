import { useCallback, useMemo } from 'react';

import { Container, CssVarsProvider, Stack, Typography } from '@mui/joy';
import { useLocale, useSurveyTheme } from 'hooks';
import { PageFooter } from 'elements/PageFooter';
import { LocaleSelector } from 'elements/locale-selector/LocaleSelector';
import { PRIMARY_COLOR } from 'lib/Enum';

const DATE_TIME_TYPE = 'DateTime';
const LOCALE_STRING_TYPE = 'LocaleString';
const DATE_FORMAT_OPTIONS = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'long' };

export const ServerErrorRenderer = (props) => {
  const {
    serverError
  } = props;

  const {
    message,
    replacement_variables,
    details: {
      survey_colour = PRIMARY_COLOR._500,
    } = {}
  } = serverError || {};

  const [locale] = useLocale() || 'en';

  const replaceVariables = useCallback(
    (lines) => {
      return lines.map(line => {
        for (let { key, value, type } of replacement_variables) {
          if (type == DATE_TIME_TYPE) value = new Date(value).toLocaleString(undefined, DATE_FORMAT_OPTIONS);
          if (type == LOCALE_STRING_TYPE) value = value?.[locale] || value?.en;
          line = line.replace(key, value);
        }
        return line;
      });
    },
    [replacement_variables, locale]
  );

  const locales = useMemo(
    () => {
      return Object.keys(message || {});
    },
    [message]
  );

  const title = useMemo(
    () => {
      if (serverError) return message?.[locale]?.title;
      return 'Something has gone wrong';
    },
    [serverError, message, locale]
  );

  const body = useMemo(
    () => {
      if (message?.[locale]?.lines) {
        let lines = message?.[locale]?.lines;
        return replaceVariables(lines);
      }

      // If the server has a title skip the body.
      if (message?.[locale]?.title) return undefined;
      return ['Sorry about that! We\'ve got our team working to fix it. Please wait a few minutes, then try reloading the page.'];
    },
    [message, locale, replaceVariables]
  );

  const { joyTheme } = useSurveyTheme({ colour: survey_colour });

  return (
    <CssVarsProvider theme={joyTheme}>
      <div className={'app'}>
        <Container
          maxWidth={'md'}
          sx={{
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <Stack
            pt={3}
            mb={{ xs: 4, md: 2 }}
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <LocaleSelector locales={locales} />
          </Stack>
          <div className={'app__centered'}>
            <Stack
              spacing={5}
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              sx={{ maxWidth: '582px', flex: 1, margin: 'auto' }}
              data-component={'error-boundary-renderer'}
            >
              <Typography
                level={'h1'}
                sx={{
                  fontWeight: 300,
                  fontSize: { xs: 48, md: 64 },
                }}
              >
                {title}
              </Typography>

              {body && (
                <Stack spacing={1}>
                  {body.map((line, index) => (
                    <Typography key={index} level={'body-md'}>
                      {line}
                    </Typography>
                  ))}
                </Stack>
              )}
            </Stack>
          </div>
        </Container>
        <PageFooter
          is_kiosk_mode={false}
        />
      </div>
    </CssVarsProvider>
  );
};
