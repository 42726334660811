import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SurveyOutletWrapper } from 'SurveyOutletWrapper';
import { ErrorRenderer } from 'elements/boundary';

const AreYouARobot = lazy(() => import('are-you-a-robot/AreYouARobot'));
const IntroWrapper = lazy(() => import('intro/IntroWrapper'));
const Demographics = lazy(() => import('demographics/Demographics'));
const Questions = lazy(() => import('questions/Questions'));
const SubmitSurvey = lazy(() => import('questions/submit/SubmitSurvey'));
const ProgressSaved = lazy(() => import('complete/ProgressSaved'));
const ThankYou = lazy(() => import('complete/ThankYou'));

export const ROUTES = () => (
  <Routes>
    <Route
      path={'/are-you-a-robot'}
      element={(
        <AreYouARobot />
      )}
    />
    <Route
      path={'/:feedbackID'}
      element={(
        <SurveyOutletWrapper />
      )}
    >
      <Route
        index
        element={(
          <IntroWrapper />
        )}
      />

      <Route
        path={'demographics'}
        element={(
          <Demographics />
        )}
      />
      <Route
        path={'questions'}
        element={(
          <Questions />
        )}
      />
      <Route
        path={'progress_saved'}
        element={(
          <ProgressSaved />
        )}
      />
      <Route
        path={'submit'}
        element={(
          <SubmitSurvey />
        )}
      />
      <Route
        path={'thank_you'}
        element={(
          <ThankYou />
        )}
      />
    </Route>
    <Route
      path={'*'}
      element={(
        <ErrorRenderer status={404} />
      )}
    />
  </Routes>
);
