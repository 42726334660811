import { useCallback } from 'react';
import OAuth from 'lib/OAuth';
import { handleBeforeUnload } from './useLeavePageDialog';

export const useResetKiosk = ({ feedbackID, survey_uuid }) => {
  const onResetKiosk = useCallback(
    () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      return window.location = `${OAuth.API_DOMAIN}/api/v2/feedback/${feedbackID}/kiosk_reset?survey_uuid=${survey_uuid}`;
    },
    [feedbackID, survey_uuid]
  );

  return { onResetKiosk };
};
