export const isAsyncFunction = functionToCheck => functionToCheck && {}.toString.call(functionToCheck) === '[object AsyncFunction]';
export const isFunction = functionToCheck => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
export const isObject = obj => obj && {}.toString.call(obj) === '[object Object]';
export const isArray = arr => arr && {}.toString.call(arr) === '[object Array]';
export const isString = str => str && {}.toString.call(str) === '[object String]';
export const isNumber = value => (/^-?\d+\.?\d*$/).test(value);
export const emailRegex = /\S+@\S+\.\S+/;

const recursivelyAssign = (val, func, checkFunc) => {
  val = checkFunc(val);
  if (isObject(val)) val = func(val);
  if (isArray(val)) val = val.map(item => recursivelyAssign(item, func, checkFunc));
  return val;
};

export const convertServerErrorsToMap = (errors = []) => {
  return errors
    .map(
      ({ attribute: key, errors, type }) => {
        const value = () => {
          if (type == 'nested_attributes') return errors;
          if (isString(errors[0])) return errors[0];
          return convertServerErrorsToMap(errors);
        };
        return ({ [key]: value() });
      }
    )
    .reduce(
      (total, field) => ({ ...total, ...field }),
      {}
    );
};

export const undefinedToNull = obj => {
  obj = { ...obj };
  Object.keys(obj)
    .forEach(key => obj[key] = recursivelyAssign(
      obj[key],
      undefinedToNull,
      val => (val === undefined ? null : val)
    ));
  return obj;
};

export const nullToUndefined = obj => {
  obj = { ...obj };
  Object.keys(obj)
    .forEach(key => obj[key] = recursivelyAssign(
      obj[key],
      nullToUndefined,
      val => (val === null ? undefined : val)
    ));
  return obj;
};

export const changeCSSVars = (variables) => {
  Object.entries(variables)
    .forEach(
      ([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      }
    );
};

const splitRGBString = c => c
  .split('(')[1]
  .replace(')', '')
  .split(',')
  .map(x => x.trim());

export const hexToRgb = hex => {
  const res = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!res) return undefined;
  return res.slice(-3).map(r => parseInt(r, 16));
};

export const rgbToHex = rgb => `#${rgb.map(c => `0${c.toString(16)}`.slice(-2)).join('')}`;

const colourStringToArray = c => {
  if (c?.slice?.(0, 1) == '#') c = hexToRgb(c);
  if (c?.match?.(/\(/g)) c = splitRGBString(c);
  return c;
};

export const calcColourWithAlpha = (colour, alpha, bg = '#FFFFFF') => {
  const valueToAlpha = (c, i) => Math.floor((alpha * c) + ((1 - alpha) * bg[i]));
  bg = colourStringToArray(bg);
  colour = colourStringToArray(colour);
  if (isArray(colour)) return rgbToHex(colour.map(valueToAlpha));
};

export const luminosityForHex = (hex) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return;
  const sRGB = rgb.map(x => x / 255);
  const [r, g, b] = sRGB.map(
    XsRGB => (XsRGB <= 0.03928) ? (XsRGB / 12.92) : Math.pow(((XsRGB + 0.055) / 1.055), 2.4)
  );
  return (r * 0.2126) + (g * 0.7152) + (b * 0.0722);
};

export const colourContrastRatio = (hex, hex2) => {
  const L1 = luminosityForHex(hex);
  const L2 = luminosityForHex(hex2);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};
