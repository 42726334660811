import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';

import { CountdownTimer } from 'elements/progress';
import { DATE_NUMBERS } from 'lib/Enum';
import { useResetKiosk } from 'hooks';
import { ModalWrapper } from './ModalWrapper';

export const TimeoutModal = (props) => {
  const {
    open,
    onContinue,
    feedbackID,
    survey_uuid
  } = props;

  const { t } = useTranslation();

  const onClose = useCallback(
    () => onContinue(false),
    [onContinue]
  );

  const { onResetKiosk } = useResetKiosk({ feedbackID, survey_uuid });

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onClick={onResetKiosk}
      title={t('Want to finish your survey later?')}
      body={(
        <Stack
          spacing={5}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography
            component={'h2'}
            id={'modal-title'}
            textColor={'inherit'}
            fontWeight={'lg'}
            mb={1}
          >
            {t('The survey is about to time out')}
          </Typography>

          <CountdownTimer
            timeout={DATE_NUMBERS.MINUTE}
            onTimeout={onResetKiosk}
          />
          <Typography id={'modal-desc'}>
            {t('Would you like to continue taking the survey?')}
          </Typography>
        </Stack>
      )}
      cancelButton={t('Back to survey')}
      continueButton={t('Restart')}
    />
  );
};
